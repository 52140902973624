import {getIndicesNombreApi} from "../indice.repository";

const getIndicesNombre = (registro, titular, tabla_ajax, paginacion, anho) => {
    return new Promise((resolve, reject) => {

        let id_registro;
        switch (registro) {
            case "PRP":
                id_registro = 1;
                break;
            case "HIP":
                id_registro = 3;
                break;
            case "PRH":
                id_registro = 4;
                break;
            default:
                id_registro = 1;
        }
        getIndicesNombreApi(id_registro, titular, tabla_ajax, paginacion, anho).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj)
            } else {
                reject({error: response.data.obj})
            }
        }).catch(error => {
            reject(error)
        });
    });
}

export default getIndicesNombre
