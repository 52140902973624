<template>

  <v-row>
    <v-col cols="12" md="9" class="pt-1 pb-0">
      <f-n-a ref="fna" :busquedaNombre="false" :campos-habilitados="true" @setFormulario="setFormulario($event)"
             :key="itemNav.id_tipo_registro" @send="buscarIndice()"></f-n-a>
    </v-col>

    <v-col cols="12" md="3" class="pt-0">
      <v-btn block class="mt-4" color="primary" @click="ejecutarBuscar()" :loading="buscando">
        <v-icon left small>
          mdi-magnify
        </v-icon>
        <span>Buscar</span>
      </v-btn>
    </v-col>

    <v-col cols="12" class="pt-4" v-show="!mostrar_link_documento && !no_encontrado && !limite_busquedas">
      <v-alert
          type="info"
          outlined
          border="left"
          :icon="false"
          class="text-center ">
        <p class="mb-0 font-md info--text">
          Aquí podrá encontrar una imagen referencial de una inscripción indicando Foja, Número y Año del título.
        </p>

      </v-alert>
    </v-col>


    <v-fade-transition>
      <v-col :key="serie_buscada.numero" cols="12" class="border" v-if="data_indice != null && !limite_busquedas">

        <v-card-text class="py-0">
          <v-row>
            <v-col cols="12" class="pt-0 pb-0 pb-md-3 px-0">
              <h3 class="font-weight-medium text-center">Índice encontrado {{ serie_buscada.foja }}-{{
                  serie_buscada.numero
                }}-{{ serie_buscada.anho }}</h3>
              <v-alert v-if="foja_no_encontrada != null" :icon="false" type="warning" text outlined
                       class="text-center my-3">
                No se encontró el índice {{ foja_no_encontrada }}-{{
                  serie_buscada.numero
                }}-{{ serie_buscada.anho }} ¿Quiso decir {{ serie_buscada.foja }}-{{
                  serie_buscada.numero
                }}-{{ serie_buscada.anho }}?
              </v-alert>
              <v-divider class="my-3"></v-divider>

              <p class="mb-0 pt-2">{{ data_indice.titular }}</p>
              <p class="mb-0 font-md pl-1 d-flex pb-2" style="font-size: 12px">
                <v-icon class="pr-2" small>mdi-map-marker</v-icon>
                <span>{{ data_indice.direccion }}</span>

                <span class="info--text pointer" v-if="getRutUsuario == null" @click="verMas()">Ver más </span>
              </p>
              <div class="text-center" v-if="no_encontrado">

                <v-btn color="primary" class="ml-1" small @click="seleccionarItem()">
                  <v-icon left small>mdi-cart-plus</v-icon>
                  <span>Agregar al carro</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>

        </v-card-text>


      </v-col>
    </v-fade-transition>


    <v-col cols="12" class="pt-0 pt-md-3" v-if="no_encontrado && !limite_busquedas">
      <v-alert color="info" :icon="false" outlined border="left">
        <p class="text-center my-0">El documento solicitado no se encuentra disponible para su visualización</p>
      </v-alert>
    </v-col>


    <v-col cols="12" class="pt-0 pt-md-3" v-if="mostrar_link_documento && !limite_busquedas">
      <v-alert color="info" :icon="false" outlined border="left">

        <p class="text-center">La imagen referencial con índice {{ serie_buscada.foja }}-{{
            serie_buscada.numero
          }}-{{ serie_buscada.anho }} se encuentra disponible</p>

        <div class="text-center">
          <v-btn color="primary" class="mr-1" small
                 @click="verDocumento(serie_buscada.foja, serie_buscada.numero, serie_buscada.anho, idDocumento)">
            <v-icon left small>mdi-magnify</v-icon>
            <span>Ver Documento</span>
          </v-btn>
          <v-btn color="primary" class="ml-1" small @click="seleccionarItem()">
            <v-icon left small>mdi-cart-plus</v-icon>
            <span>Agregar al carro</span>
          </v-btn>
        </div>

      </v-alert>

    </v-col>
    <v-fade-transition>
      <v-col cols="12" class="pt-0 pt-md-3" v-if="limite_busquedas">
        <v-alert type="info" text outlined border="left" :icon="false" class="text-center">
          <span v-if="limite_busquedas">Ha alcanzado el límite de busquedas</span>
        </v-alert>

      </v-col>
    </v-fade-transition>
    <modal-limite-alcanzado :ver-dialog="verModalLimiteBusqueda"
                            @ocultarDialog="verModalLimiteBusqueda = false"></modal-limite-alcanzado>
  </v-row>


</template>

<script>
import FNA from "@/components/SolicitudDocumentos/Formularios/FormularioFna";
import indiceEntity from "@/model/indices/indice.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";
import ModalLimiteAlcanzado from "@/views/index/indices/ModalLimiteAlcanzado";
import registros, {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";

export default {
  name: "TabFNA",
  components: {ModalLimiteAlcanzado, FNA},
  props: {
    itemNav: {default: null},
  },
  data() {
    return {
      checkbox: false,
      foja: null,
      numero: null,
      anho: null,
      idDocumento: null,
      no_encontrado: false,
      mostrar_link_documento: false,
      serie_buscada: null,
      verModalLimiteBusqueda: false,
      limite_busquedas: false,
      data_indice: null,
      foja_no_encontrada: null,
      buscando: false
    }
  },
  watch: {
    getRutUsuario() {
      if (this.serie_buscada != null) {
        this.buscarSerie(this.serie_buscada)
      }
    }
  },
  methods: {
    verMas() {
      EventBus.$emit("modal-login", true);
    },
    ejecutarBuscar() {
      this.$refs.fna.send();
    },

    setFormulario(formulario) {

      this.foja = formulario != null ? formulario.foja : null;
      this.numero = formulario != null ? formulario.numero : null;
      this.anho = formulario != null ? formulario.anho : null;

    },
    buscarIndice() {

      if (this.anho == null) {
        return;
      }

      let serie = {
        foja: this.foja,
        numero: this.numero,
        anho: this.anho,
        id_tipo_registro: this.itemNav.id_tipo_registro
      }
      this.serie_buscada = serie;
      this.data_indice = null;
      this.mostrar_link_documento = false;
      this.no_encontrado = false;
      this.limite_busquedas = false;
      this.foja_no_encontrada = null;

      this.buscarSerie(serie);

    },
    buscarSerie(serie) {
      if (serie.id_tipo_registro === ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {

        let id_tipo_certificado_fix = 0;
        switch (this.itemNav.registro) {
          case "PRP":
            id_tipo_certificado_fix = 1;
            break;
          case "HIP":
            id_tipo_certificado_fix = ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA
            break;
          case "PRH":
            id_tipo_certificado_fix = ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION
            break;
          default:
            console.error("TIPO REGISTRO")
            return;
        }
        serie.id_tipo_certificado = id_tipo_certificado_fix;
        this.buscando = true;

        //llamar a la búsqueda de

        registros.isSerie(serie.numero, serie.anho, serie.id_tipo_registro, id_tipo_certificado_fix).then(response => {
          if (response) {

            if(serie.id_tipo_registro == ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD && serie.id_tipo_certificado != 1){
              serie.id_tipo_registro = 0;
            }

            this.data_indice = response;

            if (this.serie_buscada.foja !== response.foja) {
              this.foja_no_encontrada = this.foja + ""
              serie.foja = response.foja
            }

            this.getDocumento(serie);
          } else {
            this.no_encontrado = true;
          }
        }).finally(() => {
          this.buscando = false;
        })
      } else {
        this.getDocumento(serie);
      }
    },
    getDocumento(serie) {
      indiceEntity.getDocFNA(serie).then(resp => {


        this.idDocumento = resp.obj;
        if (resp.code === "OK") {
          if (resp.obj.trim() !== "") {
            this.mostrar_link_documento = true;
            this.no_encontrado = false;

          } else {
            this.mostrar_link_documento = false;
            this.no_encontrado = true;
          }
        } else {
          this.limite_busquedas = true;
          this.verModalLimiteBusqueda = true;

        }

      })
    },
    verDocumento(foja, numero, anho, idDocumento) {
      usuarioEntity.isSessionActive().then(res => {

        if (res) {
          const serie = `${foja}-${numero}-${anho}`
          window.open(`/visor-documentos/${serie}/${idDocumento}/d`, '_blank');
        } else {
          EventBus.$emit("modal-login", true);
        }
      })
    },
    seleccionarItem() {
      this.$emit("seleccionarItem", this.serie_buscada)
    }
  },
  computed: {
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    }
  }

}
</script>

<style scoped>

</style>
