<template>
  <div class="pt-3">
    <form @submit.prevent="buscarTitulares">
      <v-row class="px-3">
        <v-col cols="12" md="6" sm="7" class="py-sm-1 pt-0 pb-3 px-extraSmallChris">
          <v-text-field
              dense
              label="Nombre y apellido(s) del propietario"
              placeholder=" "
              outlined
              :autofocus="!$vuetify.breakpoint.mobile"
              v-model="titular"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="5" class="py-sm-1 pb-3 px-extraSmallChris">
          <v-text-field
              dense
              label="Año de la inscripción"
              placeholder=" "
              outlined
              v-model="anho"
              type="number"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" class="py-0 pt-3 pt-md-0 btn-px">
          <div class="text-center ">
            <v-btn
                type="submit"
                class=" mt-1"
                height="38"
                block
                color="primary"
                :loading="cargandoTitulares"
                :disabled="cargandoTitulares">
              <v-icon small left>mdi-magnify</v-icon>
              <span>Buscar</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </form>

    <v-alert
        v-if="primeraBusqueda"
        type="info"
        outlined
        border="left"
        :icon="false"
        class="text-center mt-5">
      <p class="mb-0 font-md info--text">
        Aquí podrá encontrar los datos de inscripción de una propiedad indicando el nombre del propietario
        y el año de la inscripción.
      </p>

    </v-alert>
    <v-fade-transition>
      <panel-titulares-encontrados v-if="!primeraBusqueda" :lista-titulos="listaTitulos"
                                   @seleccionarItem="seleccionarItem" @recargarBusqueda="buscarTitulares">

      </panel-titulares-encontrados>

    </v-fade-transition>
    <modal-agregar-documento-v2 :ver-dialog="verModalAgregarDocumento"
                                :lista_certificados_prop="lista_registros"
                                :item-nav="itemNav" :item="itemSeleccionado"
                                @ocultarDialog="verModalAgregarDocumento = false"></modal-agregar-documento-v2>
  </div>
</template>

<style scoped>
.px-extraSmallChris {
  padding-right: 20px;
  padding-left: 0px;
}

.btn-px {
  padding-right: 0px;
}

.v-alert-px {
  padding-right: 20px;
  padding-left: 10px;
}


@media(max-width: 1263px) {
  .px-extraSmallChris {
    padding-right: 10px;
    padding-left: 0px;
  }

  .btn-px {
    padding-right: 0px;
    padding-left: 0px;

  }

  .v-alert-px {
    padding-right: 0px;
    padding-left: 10px;
  }
}
@media(max-width: 600px){
  .px-extraSmallChris{
    padding-right: 0px;
    padding-left: 0px;
  }
  .btn-px{
    padding-left: 0px;
    padding-right: 0px;
  }
  .v-alert-px{
    padding-right: 5px;
    padding-left: 5px;
  }
}

</style>

<script>
import tituloEntity from "@/model/titulo/titulo.entity";
import PanelTitularesEncontrados from "@/views/index/indices/PanelTitularesEncontrados";
import ModalAgregarDocumentoV2 from "@/views/index/indices/ModalAgregarDocumentoV2";

/**
 *
 */
export default {
  name: "TabTitulares",
  components: {ModalAgregarDocumentoV2, PanelTitularesEncontrados},
  props: {
    itemNav: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      titular: "",
      buscando: false,
      anho: "",
      verModalTitularesEncontrados: false,
      listaTitulos: [],
      cargandoTitulares: false,
      primeraBusqueda: true,
      verModalAgregarDocumento: false,
      lista_registros: [],
      itemSeleccionado: null
    }
  },

  methods: {
    seleccionarItem(item, registros) {
      console.log("se va a mostrar el nuevo modal")
      this.lista_registros = registros;
      this.verModalAgregarDocumento = true;
      this.itemSeleccionado = item;
      console.log("vienen los registros?", registros)
      //  this.$emit("seleccionarItem", item, registros)
    },
    /**
     * Método que se encarga de realizar una petición al backend con el titular y el año digitado para buscar los índices
     * que se pueden agregar
     */
    buscarTitulares() {
      this.cargandoTitulares = true;
      console.log("buscando titulares")
      tituloEntity.getConsultaTitulares(this.titular, this.anho).then(res => {
        this.primeraBusqueda = false;
        this.cargandoTitulares = false;
        this.listaTitulos = res.obj;
        this.verModalTitularesEncontrados = true;
      })
    }
  }
}
</script>


