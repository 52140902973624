<template>
  <div>
    <div v-if="!$vuetify.breakpoint.smAndDown">
      <v-row v-if="itemNav != null">
        <v-col cols="12" class="text-center pt-0 pl-md-5" v-if="itemNav != null">

          <v-tabs
              centered
              center-active
              show-arrows
              v-model="tipo_formulario"
          >
            <v-tab v-if="itemNav.menus.includes(5)" href="#fna-scbr">Búsqueda por Foja, Número y Año</v-tab>
            <v-tab-item
                value="fna-scbr"
                v-if="itemNav.menus.includes(5)"
            >
              <v-card-text class="px-0 pr-extraSmallChris">
                <tabFNASCBR @seleccionarItem="abrirModalAgregar" :item-nav="itemNav"></tabFNASCBR>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(0)" href="#fna">Foja, Número y Año</v-tab>
            <v-tab-item
                value="fna"
                v-if="itemNav.menus.includes(0)"
            >
              <v-card-text class="px-0">
                <tab-f-n-a :key="itemNav.registro"
                           @seleccionarItem="abrirModalAgregar($event)"
                           :id_tipo_registro="itemNav.id_tipo_registro"
                           :item-nav="itemNav"

                ></tab-f-n-a>
              </v-card-text>
            </v-tab-item>

              <v-tab v-if="itemNav.menus.includes(6)" href="#nombreSociedad">Búsqueda por nombre de Sociedad</v-tab>
              <v-tab-item
                value="nombreSociedad"
                v-if="itemNav.menus.includes(6)"
              >
                  <v-card-text class="px-0">
                      <tab-nombre-sociedad
                        @seleccionarItem="abrirModalAgregar($event)"
                      >

                      </tab-nombre-sociedad>
                  </v-card-text>
              </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(1)" href="#apellido">Nombre y apellidos</v-tab>
            <v-tab-item
                value="apellido"
                v-if="itemNav.menus.includes(1)"
            >
              <v-card-text>
                <tab-apellido @seleccionarItem="abrirModalAgregar($event)"
                              :item-nav="itemNav"
                ></tab-apellido>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(3)" href="#planos">Búsqueda de planos</v-tab>
            <v-tab-item
                value="planos"
                v-if="itemNav.menus.includes(3)"
            >

              <v-card-text>
                <tab-planos :itemNav="itemNav" @seleccionarItem="abrirModalAgregar($event)"></tab-planos>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(4)" href="#titulares">Búsqueda por nombres y apellidos</v-tab>
            <v-tab-item
                value="titulares"
                v-if="itemNav.menus.includes(4)"
            >
              <v-card-text class="px-0">
                <tab-titulares @seleccionarItem="abrirModalAgregar" :item-nav="itemNav"></tab-titulares>
              </v-card-text>
            </v-tab-item>
          </v-tabs>

          <!--  <v-btn-toggle
                v-model="tipo_formulario"
                borderless
                mandatory

            >
              <v-btn value="0" class="btn-tabs px-5" v-if="itemNav.menus.includes(0)">
                <v-icon left>
                  mdi-folder-text-outline
                </v-icon>
                <span>Foja, Número y Año</span>
              </v-btn>

              <v-btn value="1" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(1)">
                <v-icon left>
                  mdi-account
                </v-icon>
                <span>Nombre y apellidos </span>
              </v-btn>

              <v-btn value="3" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(3)">
                <v-icon left>
                  mdi-map
                </v-icon>
                <span>Búsqueda de planos </span>
              </v-btn>

            </v-btn-toggle>
            <v-divider class="mt-3"></v-divider>
            -->
        </v-col>


        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '0'">
          <p>0</p>
        </v-col>

        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '1'">
          <p>1</p>
        </v-col>
        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '3'">
          <p>3</p>
        </v-col>

      </v-row>
    </div>
    <div v-else>
      <v-row v-if="itemNav != null">
        <v-col cols="12" class=" pt-0 pl-md-5" v-if="itemNav != null">
          <div class="pl-2">Busqueda por:</div>
          <v-tabs
              centered
              center-active
              v-model="tipo_formulario"
          >
            <v-tab v-if="itemNav.menus.includes(5)" href="#fna-scbr">Foja, Número y Año</v-tab>
            <v-tab-item
                value="fna-scbr"
                v-if="itemNav.menus.includes(5)"
            >
              <v-card-text class="px-0 pr-extraSmallChris">
                <tabFNASCBR @seleccionarItem="abrirModalAgregar" :item-nav="itemNav"></tabFNASCBR>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(0)" href="#fna">Foja, Número y Año</v-tab>
            <v-tab-item
                value="fna"
                v-if="itemNav.menus.includes(0)"
            >
              <v-card-text class="px-0">
                <tab-f-n-a :key="itemNav.registro"
                           @seleccionarItem="abrirModalAgregar($event)"
                           :id_tipo_registro="itemNav.id_tipo_registro"
                           :item-nav="itemNav"

                ></tab-f-n-a>
              </v-card-text>
            </v-tab-item>
              <v-tab v-if="itemNav.menus.includes(6)" href="#nombreSociedad">Búsqueda por nombre Sociedad</v-tab>
              <v-tab-item
                      value="nombreSociedad"
                      v-if="itemNav.menus.includes(6)"
              >
                  <v-card-text class="px-0 pr-extraSmallChris">
                      <tab-nombre-sociedad
                              @seleccionarItem="abrirModalAgregar($event)"
                      >

                      </tab-nombre-sociedad>
                  </v-card-text>
              </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(1)" href="#apellido">Nombre y apellidos</v-tab>
            <v-tab-item
                value="apellido"
                v-if="itemNav.menus.includes(1)"
            >
              <v-card-text>
                <tab-apellido @seleccionarItem="abrirModalAgregar($event)"
                              :item-nav="itemNav"
                ></tab-apellido>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(3)" href="#planos">de planos</v-tab>
            <v-tab-item
                value="planos"
                v-if="itemNav.menus.includes(3)"
            >

              <v-card-text>
                <tab-planos :itemNav="itemNav" @seleccionarItem="abrirModalAgregar($event)"></tab-planos>
              </v-card-text>
            </v-tab-item>

            <v-tab v-if="itemNav.menus.includes(4)" href="#titulares">nombres y apellidos</v-tab>
            <v-tab-item
                value="titulares"
                v-if="itemNav.menus.includes(4)"
            >
              <v-card-text class="px-0">
                <tab-titulares @seleccionarItem="abrirModalAgregar" :item-nav="itemNav"></tab-titulares>
              </v-card-text>
            </v-tab-item>
          </v-tabs>

          <!--  <v-btn-toggle
                v-model="tipo_formulario"
                borderless
                mandatory

            >
              <v-btn value="0" class="btn-tabs px-5" v-if="itemNav.menus.includes(0)">
                <v-icon left>
                  mdi-folder-text-outline
                </v-icon>
                <span>Foja, Número y Año</span>
              </v-btn>

              <v-btn value="1" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(1)">
                <v-icon left>
                  mdi-account
                </v-icon>
                <span>Nombre y apellidos </span>
              </v-btn>

              <v-btn value="3" class="btn-tabs px-5 py-1" v-if="itemNav.menus.includes(3)">
                <v-icon left>
                  mdi-map
                </v-icon>
                <span>Búsqueda de planos </span>
              </v-btn>

            </v-btn-toggle>
            <v-divider class="mt-3"></v-divider>
            -->
        </v-col>


        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '0'">
          <p>0</p>
        </v-col>

        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '1'">
          <p>1</p>
        </v-col>
        <v-col cols="12" class="py-0 pl-md-5" v-if="tipo_formulario === '3'">
          <p>3</p>
        </v-col>

      </v-row>
    </div>
      <!--
      <modal-agregar-documento :item="item_modal" :itemNav="itemNav" :lista_registros="lista_registros"
                               :cargar-registros="cargarRegistros"
                               :ver-dialog="verModalAgregarDocumento"
                               @ocultarDialog="verModalAgregarDocumento = false"
                               :key="`attempt-${attempt}`"
                               @verModalDocumentosAgregados="verModalDocumentosAgregados($event)"
      ></modal-agregar-documento>
      -->
      <modal-agregar-documento-v2
          :item="item_modal"
          :itemNav="itemNav"
          :lista_certificados_prop="lista_registros"
          :ver-dialog="verModalAgregarDocumento"
          @ocultarDialog="verModalAgregarDocumento = false"
          @verModalDocumentosAgregados="verModalDocumentosAgregados($event)"

      />
      <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                                :documentosAgregados="modalAgregar.lista_agregados"
                                :documentosRepetidos="modalAgregar.documentos_repetidos"
                                :documentosEnLimite="modalAgregar.limite_carro"
                                @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>

  </div>
</template>

<script>
import TabFNA from "@/views/index/indices/TabFNA";
import TabApellido from "@/views/index/indices/TabApellido";
import registrosEntity from "@/model/registros/registros.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import TabPlanos from "@/views/index/indices/TabPlanos";
import TabTitulares from "@/views/index/indices/TabTitulares";
import TabFNASCBR from "@/views/index/indices/TabFNASCBR";
import ModalAgregarDocumentoV2 from "@/views/index/indices/ModalAgregarDocumentoV2";
import TabNombreSociedad from "@/views/index/indices/TabNombreSociedad.vue";


export default {
  name: "IndicesViewTipo",
  components: {
      TabNombreSociedad,
    ModalAgregarDocumentoV2,
    TabTitulares, TabPlanos, ModalDocumentoAgregado, TabApellido, TabFNA, TabFNASCBR
  },
  props: {
    itemNav: {default: null}
  },
  data() {
    return {
      tipo_formulario: "0",
      verModalAgregarDocumento: false,
      lista_registros: [],
      item_modal: null,
      modalAgregar: {
        verModalDocumentoAgregado: false,
        lista_agregados: [],
        documentos_repetidos: [],
        limite_carro: []
      },
      cargarRegistros: true,
      attempt: 0
    }
  },
  created() {

  },
  methods: {
    abrirModalAgregar(item, registros) {
      console.log("que viene en registros: ", registros)
      console.log("que viene en item: ", item)
      this.attempt++;
      this.url_categoria = "comercio";
      setTimeout(() => {
        if (registros != null) {
          console.log("registros no nulos: ", registros);
          this.lista_registros = registros
          this.item_modal = item;
          this.verModalAgregarDocumento = true;
          this.cargarRegistros = false;
        } else {
          this.cargarRegistros = true
          registrosEntity.getTiposRegistrosFromCategoria(this.url_categoria)
              .then((response) => {
                console.log("response lista_registros: ", response[0].tipos_certificados);
                //transformación de tipos certificados a formato actual
                let certificadosTransformados = response[0].tipos_certificados;
                certificadosTransformados = certificadosTransformados.map((cert) => {
                  cert.numero_copias = 0;
                  cert.vigencia = -1;
                  cert.has_litigio = false;
                  return cert;
                });
                  if (item.constitucion === undefined) {
                      console.log("undefined")
                      this.lista_registros = certificadosTransformados;
                  }else{
                      if(item.constitucion === true){
                          console.log("true")
                          this.lista_registros = certificadosTransformados;
                      }else{
                          console.log("false")
                          certificadosTransformados.map(certificado =>{
                              if(certificado.id_tipo_certificado === 1){
                                  this.lista_registros = [certificado];
                              }
                          })
                      }
                  }

                this.item_modal = item;
                this.verModalAgregarDocumento = true;
              })
              .catch((error) => {
                console.error(error)
                alert(error);
              })
              .finally(() => {
              })
        }
      }, 10) //Timeout para que el modal refresque


    },
    verModalDocumentosAgregados(data) {
      this.modalAgregar.verModalDocumentoAgregado = true;
      this.modalAgregar.lista_agregados = data.lista_agregados;
      this.modalAgregar.limite_carro = data.limite_carro;
      this.modalAgregar.documentos_repetidos = data.documentos_repetidos;
    }
  },
  watch: {
    getRegistro() {
      this.tipo_formulario = this.itemNav.menus[0].toString();
    }

  },
  computed: {
    getRegistro() {
      if (this.itemNav != null) {
        return this.itemNav.registro;
      } else {
        return 0;
      }

    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .pr-extraSmallChris {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.btn-tabs {
  height: 36px !important;
}

</style>
