import getIndicesNombre from "./usecases/get-indices-nombre";
import getDocFna from "./usecases/get-doc-fna";
import getPDFFna from "./usecases/get-pdf";

const indiceEntity = {
    getIndicesNombre: getIndicesNombre,
    getDocFNA: getDocFna,
    getPDF: getPDFFna
}

export default indiceEntity;