<template>
  <div class="pt-3">
    <form @submit.prevent="buscarTitulares">
      <v-row class="pr-hastaXLChris">

        <v-col cols="12" sm="3" class="py-sm-1 pt-0 pb-3 pr-extraSmallChris">
          <v-text-field
              label="Foja"
              placeholder="Ej: 1"
              outlined
              id="inputFoja"
              dense
              :autofocus="!$vuetify.breakpoint.mobile"
              hide-details="auto"
              v-model="foja"
              @input="delayTouch($v.foja)"
              :error-messages="errorFoja"
          />
        </v-col>

        <v-col cols="12" sm="3" class="py-sm-1 pb-3 pr-extraSmallChris">
          <v-text-field
              label="Número"
              placeholder="Ej: 1"
              outlined
              dense
              hide-details="auto"
              v-model="numero"
              @input="delayTouch($v.numero)"
              :error-messages="errorNumero"
          />
        </v-col>

        <v-col cols="12" sm="3" class="py-sm-1 pb-3 pr-extraSmallChris">
          <v-text-field
              label="Año"
              placeholder="Ej: 2022"
              outlined
              dense
              hide-details="auto"
              v-model="anho"
              @input="delayTouch($v.anho)"
              :error-messages="errors.errorAnho($v.anho)"
          />
        </v-col>

        <v-col cols="12" sm="3" class="py-0 pt-0 pt-md-0 pr-0 btn-pr">
          <div class=" btn-pr ">
            <v-btn
                type="submit"
                class="px-10 mt-1"
                height="38"
                block
                color="primary"
                :loading="cargandoTitulares"
                :disabled="cargandoTitulares">
              <v-icon small left>mdi-magnify</v-icon>
              <span>Buscar</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </form>
    <div class="v-alert-px">
      <v-alert
          v-if="primeraBusqueda"
          type="info"
          outlined
          border="left"
          :icon="false"
          class="text-center mt-5">
        <p class="mb-0 font-md info--text">
          Aquí podrá encontrar los datos de inscripción de una propiedad indicando Foja, Número y Año del título.
        </p>

      </v-alert>
    </div>
    <v-fade-transition class="">
      <panel-titulares-encontrados class="px-data-table-0" v-if="!primeraBusqueda" :lista-titulos="listaTitulos"
                                   @seleccionarItem="seleccionarItem" @recargarBusqueda="buscarTitulares">

      </panel-titulares-encontrados>

    </v-fade-transition>
    <modal-agregar-documento-v2 :ver-dialog="verModalAgregarDocumento"
                                :lista_certificados_prop="lista_registros"
                                :item-nav="itemNav" :item="itemSeleccionado"
                                @ocultarDialog="verModalAgregarDocumento = false"></modal-agregar-documento-v2>
  </div>
</template>

<script>
import tituloEntity from "@/model/titulo/titulo.entity";
import PanelTitularesEncontrados from "@/views/index/indices/PanelTitularesEncontrados";
import ModalAgregarDocumentoV2 from "@/views/index/indices/ModalAgregarDocumentoV2";
import {required, between, helpers} from 'vuelidate/lib/validators'
import validEntity from "@/model/validaciones/valid.entity";

const touchMap = new WeakMap();

const validadorFoja = helpers.regex('validadorFoja', /^([0-9])+(BIS|V|BISV|VBIS)?$/);
const validadorNumero = helpers.regex('validadorNumero', /^([0-9])+(BIS)?$/);

export default {
  name: "TabFNASCBR",
  components: {ModalAgregarDocumentoV2, PanelTitularesEncontrados},
  props: {
    itemNav: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      titular: "",
      buscando: false,
      foja: "",
      numero: "",
      anho: "",
      verModalTitularesEncontrados: false,
      listaTitulos: [],
      cargandoTitulares: false,
      primeraBusqueda: true,
      verModalAgregarDocumento: false,
      lista_registros: [],
      itemSeleccionado: null,
      //
      errors: validEntity,
    }
  },
  validations: {
    anho: {
      required,
      between: between(1937, new Date().getFullYear())
    },
    foja: {
      required,
      validadorFoja
    },
    numero: {
      required,
      validadorNumero
    }
  },
  computed: {
    errorFoja() {
      const errors = [];
      if (!this.$v.foja.$dirty) return errors;
      !this.$v.foja.required && errors.push("Ingrese una foja");
      !this.$v.foja.validadorFoja && errors.push("Ingrese una foja válida (Sólo números o números seguido de BIS, V, VBIS o BISV en mayúsculas, sin espacios)");
      return errors;
    },
    errorNumero() {
      const errors = [];
      if (!this.$v.numero.$dirty) return errors;
      !this.$v.numero.required && errors.push("Ingrese un numero");
      !this.$v.numero.validadorNumero && errors.push("Ingrese un número válido (Sólo números o números seguido de BIS en mayúsculas, sin espacios)");
      return errors;
    },
  },
  methods: {
    seleccionarItem(item, registros) {
      console.log("se va a mostrar el nuevo modal")
      this.lista_registros = registros;
      this.verModalAgregarDocumento = true;
      this.itemSeleccionado = item;
      console.log("vienen los registros?", registros)
      //  this.$emit("seleccionarItem", item, registros)
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 800))
    },
    /**
     * Método que se encarga de realizar una petición al backend con el titular y el año digitado para buscar los índices
     * que se pueden agregar
     */
    buscarTitulares() {
      this.cargandoTitulares = true;
      console.log("buscando titulares")

      const indice = this.foja + "-" + this.numero + "-" + this.anho;

      tituloEntity.getConsultaTitularesByIndice(indice).then(res => {
        this.primeraBusqueda = false;
        this.cargandoTitulares = false;
        this.listaTitulos = res.obj;
        this.verModalTitularesEncontrados = true;
      })
    }
  }
}
</script>

<style scoped>
  .pr-extraSmallChris {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn-pr {
    padding-right: 30px;
  }

  .v-alert-px {
    padding-right: 20px;
    padding-left: 10px;
  }

  .px-data-table-0{
    padding-right:20px;
    padding-left: 10px;
  }

@media(max-width: 1263px) {
  .pr-extraSmallChris {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn-pr {
    padding-right: 10px;
    padding-left: 0px;

  }

  .v-alert-px {
    padding-right: 0px;
    padding-left: 10px;
  }
}
@media(max-width: 600px){
  .pr-extraSmallChris{
    padding-right: 15px;
    padding-left: 15px;
  }
  .px-data-table-0{
    padding-right: 0px;
    padding-left: 0px;
  }
  .btn-pr{
    padding-left: 8px;
    padding-right: 15px;
  }
  .v-alert-px{
    padding-right: 5px;
    padding-left: 5px;
  }
}


</style>