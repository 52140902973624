<template>
  <v-row :key="itemNav.registro">
    <v-col cols="12" class="pb-0">
      <v-form @submit.prevent="buscarIndices()">
        <v-row>
          <v-col cols="12" md="6" sm="7" class="pb-sm-0 pt-0 pb-3">
            <v-text-field
                dense
                label="Nombre y apellido(s) del propietario"
                placeholder=" "
                outlined
                :autofocus="!$vuetify.breakpoint.mobile"
                v-model="titular"
                :error-messages="errors.errorNombreCompleto($v.titular)"
                @input="delayTouch($v.titular)"
                hide-details="auto"
                @blur="$v.titular.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="5" class="py-sm-0 pb-3">
            <v-text-field
                dense
                label="Año de la inscripción"
                placeholder=" "
                outlined
                v-model="anho"
                type="number"
                hide-details="auto"
                :error-messages="errors.errorAnho($v.anho)"
                @input="delayTouch($v.anho)"
                @blur="$v.anho.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" class="py-0 pt-3 pt-md-0">
            <div class="text-center ">
              <v-btn
                  type="submit"
                  class="px-10 mt-1"
                  block
                  color="primary"
                  :loading="buscando"
              >
                <v-icon small left>mdi-magnify</v-icon>
                <span>Buscar</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>


      <v-row>
        <v-fade-transition>
          <v-col cols="12" class="pt-4" v-if="indices.length > 0 && !buscando">
            <indices-table :datas="indices" :itemsTotales="cantidad_indices"
                           @seleccionarItem="seleccionarItem($event)"
                           @cambiarPagina="cambiarPagina($event)"></indices-table>
          </v-col>
        </v-fade-transition>


        <v-fade-transition>
          <v-col cols="12" class="pt-4" v-if="busqueda_realizada && indices.length === 0 && !buscando">
            <v-alert type="info" text outlined border="left" :icon="false" class="text-center">
              <span v-if="!limite_busquedas">No existen registros para mostrar</span>
              <span v-if="limite_busquedas">Ha alcanzado el límite de busquedas</span>
            </v-alert>

          </v-col>
        </v-fade-transition>


        <v-col cols="12" class="pt-4" v-if="!busqueda_realizada && !buscando && ver_ayuda">
          <v-alert
              type="info"
              outlined
              border="left"
              :icon="false"
              class="text-center ">
            <p class="mb-0 font-md info--text">
              Aquí podrá encontrar los datos de inscripción de una propiedad indicando el nombre del propietario
              y el año de la inscripción.
            </p>

          </v-alert>
        </v-col>

        <v-col cols="12" class="pt-6" v-if="buscando">
          <v-progress-linear
              color="info lighten-2"
              buffer-value="0"
              stream
              reverse
          ></v-progress-linear>
        </v-col>

      </v-row>


    </v-col>
    <modal-limite-alcanzado :ver-dialog="verModalLimiteBusqueda"
                            @ocultarDialog="verModalLimiteBusqueda = false"></modal-limite-alcanzado>
  </v-row>
</template>

<script>
import {required, between} from 'vuelidate/lib/validators'
import indiceEntity from "@/model/indices/indice.entity";
import IndicesTable from "@/views/index/indices/IndicesTable";
import validEntity from "@/model/validaciones/valid.entity";
import {nombreApellidoValidate} from "@/model/validaciones/valid.entity";
import ModalLimiteAlcanzado from "@/views/index/indices/ModalLimiteAlcanzado";

const touchMap = new WeakMap();


export default {
  name: "TabApellido",
  components: {ModalLimiteAlcanzado, IndicesTable},
  props: {
    itemNav: {default: null},
    ver_ayuda: {default: true}
  },
  data() {
    return {
      titular: "",
      indices: [],
      cantidad_indices: 0,
      titularBuscado: "",
      busqueda_realizada: false,
      buscando: false,
      anho: null,
      anho_buscado: null,
      errors: validEntity,
      limite_busquedas: false,
      verModalLimiteBusqueda: false,
      cargandoIndices: false
    }
  },
  validations: {
    titular: {required, nombreApellidoValidate},
    anho: {
      required,
      between: between(1937, new Date().getFullYear())
    }
  },
  watch: {
    itemNav() {
      this.titular = "";
      this.indices = [];
      this.cantidad_indices = 0;
      this.titularBuscado = "";
      this.busqueda_realizada = false;
      this.buscando = false;
      this.anho = null;
      this.anho_buscado = null;
      this.$v.$reset();
    }
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },

    seleccionarItem(item) {
      this.$emit("seleccionarItem", item)
    },
    buscarIndices() {

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let tabla_ajax_sim = {
        draw: 1,
        columns: [],
        order: [],
        start: 0,
        length: 5,
        search: {value: "", regex: false}
      }
      this.buscando = true;
      this.titularBuscado = this.titular + "";
      this.anho_buscado = parseInt(this.anho);
      this.busqueda_realizada = true;
      indiceEntity.getIndicesNombre(this.itemNav.registro, this.titular, tabla_ajax_sim, false, this.anho_buscado).then(res => {
        if (!res) {
          this.indices = [];
          this.limite_busquedas = true;
          this.verModalLimiteBusqueda = true;
        } else {
          this.indices = res.data
          this.cantidad_indices = res.recordsTotal;
          this.limite_busquedas = false;
        }

      }).catch(error => {
        console.error("Error: ", error)
      }).finally(() => {
        this.buscando = false;
      })
    },
    cambiarPagina(page) {
      let tabla_ajax_sim = {
        draw: page,
        columns: [],
        order: [],
        start: (page - 1) * 5,
        length: 5,
        search: {value: "", regex: false}
      }
      indiceEntity.getIndicesNombre(this.itemNav.registro, this.titularBuscado, tabla_ajax_sim, true, this.anho_buscado).then(res => {
        this.indices = res.data
        this.cantidad_indices = res.recordsTotal
      }).catch(error => {
        console.error("Error: ", error)
      })
    }
  }
}
</script>

<style scoped>

</style>
